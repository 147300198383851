import React, { useEffect, useState } from 'react';
import { DiagramModel } from '@projectstorm/react-diagrams';
import { DiagramEngine, LinkModel, PortModel, PortModelGenerics } from '@projectstorm/react-diagrams-core';

import styles from './ReportList.module.scss';
import { ReportSidebar } from "../../fragments/ReportSidebar/ReportSidebar";
import { ItemForm } from '../../fragments/ItemForm/ItemForm';
import { CompareModal } from '../../fragments/CompareModal/CompareModal';


import { useAppContext } from '../../services/AppContext';
import { useAuth } from '../../services/AuthContext';
import { ComponentNodeModel } from '../../react-diagrams/entities/nodes/component/ComponentNodeModel';
import { ShippingNodeModel } from '../../react-diagrams/entities/nodes/shipping/ShippingNodeModel';
import { SupplierNodeModel } from '../../react-diagrams/entities/nodes/supplier/SupplierNodeModel';

import { ServerParentList} from '../../fragments/NodeViewSidebar/NodeViewSidebar';
import { DiagramState } from '../../react-diagrams/states/DiagramState';
import { ProductNodeFactory } from '../../react-diagrams/entities/nodes/product/ProductNodeFactory';
import { CustomerNodeFactory } from '../../react-diagrams/entities/nodes/customer/CustomerNodeFactory';
import { ChooseNodeFactory } from '../../react-diagrams/entities/nodes/choose/ChooseNodeFactory';
import { SupplierNodeFactory } from '../../react-diagrams/entities/nodes/supplier/SupplierNodeFactory';
import { ComponentNodeFactory } from '../../react-diagrams/entities/nodes/component/ComponentNodeFactory';
import { ShippingNodeFactory } from '../../react-diagrams/entities/nodes/shipping/ShippingNodeFactory';
import { MyLinkFactory } from '../../react-diagrams/entities/links/MyLinkFactory';
import { MyPortFactory } from '../../react-diagrams/entities/ports/MyPortFactory';
import { LinkModelGenerics } from '@projectstorm/react-diagrams';

interface ReportListProps {
  type: string;
}
export const ReportList = (props: ReportListProps) => {
    const [items, setItems] = useState<{[key: string]: any}[]>([]);
    const [products, setProducts] = useState<{[key: string]: any}[]>([]);
	const [parentList, setParentList] = useState<ServerParentList>({customers: [], products: []});

    const appContext = useAppContext();
    const auth = useAuth();
    //const [rowCount, setRowCount] = React.useState<number|any>(false);
    let rowCount = 0;

	useEffect(() => {
		appContext.diagramEngine.getNodeFactories().registerFactory(new CustomerNodeFactory());
		appContext.diagramEngine.getNodeFactories().registerFactory(new ProductNodeFactory());
		appContext.diagramEngine.getNodeFactories().registerFactory(new ChooseNodeFactory());
		appContext.diagramEngine.getNodeFactories().registerFactory(new SupplierNodeFactory());
		appContext.diagramEngine.getNodeFactories().registerFactory(new ShippingNodeFactory());
		appContext.diagramEngine.getNodeFactories().registerFactory(new ComponentNodeFactory());
		appContext.diagramEngine.getLinkFactories().registerFactory(new MyLinkFactory());
		appContext.diagramEngine.getPortFactories().registerFactory(new MyPortFactory());

		auth.getItems(props.type).then((response: any) => {
			setItems(response.data);
		});

		auth.getItems('product').then((response: any) => {
			setProducts(response.data);
		});
		getDiagram('');
	}, [props.type])


	const getDiagram = (id: string) => {
		auth.getNodeDiagram(id)
			.then((result:any) => {
				var model2 = new DiagramModel();
				model2.deserializeModel(result.data.model, appContext.diagramEngine);

				appContext.diagramEngine.setModel(model2);

				setParentList(result.data.parents);

				appContext.setCurrParentID(result.data.currParentID);
			});
	}

	const editItem = (id: string) => {
		appContext.setModalContent(<ItemForm onSuccess={editSuccess} onFailure={editFailure} id={id} />);

		appContext.showModal();
	}

	const getKeys = (type: string):{label: string, key: string, editLink:boolean}[] => {
		const keys:{[key:string]: {label: string, key: string, editLink:boolean}[]} = {
			product: [
				{
					label:'',
					key:'selected',
					editLink:false
				},
				{
					label: 'PRODUCT NAME',
					key: 'name',
					editLink: true,
				},
                {
					label: 'SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
                {
					label: 'SKU/INVENTORY CODE',
					key: 'sku',
					editLink: false,
				},
				{
					label: 'TYPE',
					key: 'product_type',
					editLink: false,
				},
                {
					label: 'DESCRIPTION',
					key: 'description',
					editLink: false,
				},
                {
					label: 'Critical Supply Chain Considerations',
					key: 'critical_considerations',
					editLink: false,
				},
				{
					label: 'LEAD TIME',
					key: 'sum_lead_days',
					editLink: false,
				},
				{
					label: 'LANDING COST',
					key: 'sum_landed_costs',
					editLink: false,
				},
				{
					label: 'CUSTOMS/TERIFFS',
					key: 'sum_tariffs',
					editLink: false,
				},
				{
					label: 'RECOVERY TIME',
					key: 'RecoveryTime',
					editLink: false,
				},
			],
			component: [
				{
					label:'',
					key:'selected',
					editLink:false
				},
				{
					label: 'COMPONENT NAME',
					key: 'name',
					editLink: true,
				},
                {
					label: 'SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
                {
					label: 'VENDOR PART NUMBER/SKU',
					key: 'vendor_part_number',
					editLink: false,
				},
				{
					label: 'UPC',
					key: 'upc',
					editLink: false,
				},
				{
					label: 'DESCRIPTION',
					key: 'description',
					editLink: false,
				},
                {
					label: 'VENDOR INFORMATION',
					key: 'vendor_information',
					editLink: false,
				},
                {
					label: 'COST',
					key: 'cost',
					editLink: false,
				},
				{
					label: 'CASE QUANTITY',
					key: 'case_quantity',
					editLink: false,
				},
				{
					label: 'CASE SIZE',
					key: 'case_size',
					editLink: false,
				},
				{
					label: 'TERIFF CLASSIFICATION',
					key: 'tariff_classification',
					editLink: false,
				},
				{
					label: 'APPLICABLE TRADE AGREEMENTS',
					key: 'applicable_trade_agreements',
					editLink: false,
				},
				{
					label: 'INCOTERMS',
					key: 'incoterms',
					editLink: false,
				},
			],
			supplier: [
				{
					label:'',
					key:'selected',
					editLink:false
				},
				{
					label: 'SUPPLIER NAME',
					key: 'name',
					editLink: true,
				},
				{
					label: 'CONSIDERATIONS',
					key: 'qualifications',
					editLink: false,
				},
				{
					label: '#SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
				{
					label: 'NAICS',
					key: 'naics',
					editLink: false,
				},
				{
					label: 'SUPPLIER CATEGORY',
					key: 'category',
					editLink: false,
				},
				{
					label: 'QUALITY SYSTEMS (ISO)',
					key: 'iso',
					editLink: false,
				},
				{
					label: 'ON TIME DELIVERY PERFORMANCE %',
					key: 'onTime',
					editLink: false,
				},
				{
					label: 'TERMS',
					key: 'terms',
					editLink: false,
				},
				{
					label: 'ORDER LEAD TIME REQUIREMENTS - # OF DAYS',
					key: 'lead_days',
					editLink: false,
				},
				{
					label: 'ORDER LEAD TIME REQUIREMENTS - DETAILS',
					key: 'lead_days_details',
					editLink: false,
				},
				{
					label: 'TRANSPORTATION ROUTES AVAILABLE',
					key: 'transport_routes_available',
					editLink: false,
				},
				{
					label: 'FEES CHARGED BY SUPPLIER',
					key: 'fees_charged_by_supplier',
					editLink: false,
				},
				{
					label: 'CUSTOMS/TARIFFS APPLIED BY SUPPLIER',
					key: 'tariffs_applied_by_supplier',
					editLink: false,
				},
				{
					label: 'INSURANCE COSTS APPLIED BY SUPPLIER',
					key: 'insurance_applied_by_supplier',
					editLink: false,
				},
				{
					label: 'AVERAGE PAYMENT TERMS',
					key: 'average_payment_terms',
					editLink: false,
				},
			   

			],
			customer: [
				{
					label: 'CUSTOMER NAME',
					key: 'name',
					editLink: true,
				},
				{
					label: '#SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
				{
					label: 'NAICS',
					key: 'naics',
					editLink: false,
				},
				{
					label: 'CUSTOMER CATEGORY',
					key: 'customer_category',
					editLink: false,
				},
				{
					label: 'CRITICAL CUSTOMER REQUIREMENTS',
					key: 'critical_customer_requirements',
					editLink: false,
				},
				{
					label: 'SHIPMENT FREQUENCY',
					key: 'shipment_frequency',
					editLink: false,
				},
				{
					label: 'ORDER/DELIVERY REQUIREMENTS',
					key: 'order_requirements',
					editLink: false,
				},
				{
					label: 'TRANSPORT ROUTES AVAILABLE',
					key: 'transport_routes',
					editLink: false,
				},
				{
					label: 'SHIPPING INTERMEDIARIES AVAILABLE',
					key: 'Shipping_intermediaries_available',
					editLink: false,
				},
				{
					label: 'CUSTOMS/TERIFFS CHARGED',
					key: 'teriffs_applied_to_customer',
					editLink: false,
				},
				{
					label: 'INSURANCE COSTS CHARGED',
					key: 'insurance_costs',
					editLink: false,
				},
				{
					label: 'PAYMENT TERMS',
					key: 'payment_terms',
					editLink: false,
				},


			],
			shipping: [
				{
					label:'',
					key:'selected',
					editLink:false
				},
				{
					label: 'SHIPPING INTERMEDIARY NAME',
					key: 'name',
					editLink: true,
				},
				{
					label: 'QUALIFICATIONS',
					key: 'qualifications',
					editLink: false,
				},
				{
					label: '#SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
				{
					label: 'NAICS',
					key: 'naics',
					editLink: false,
				},
				{
					label: 'CATEGORY',
					key: 'category',
					editLink: false,
				},
				{
					label: 'ORDER LEAD TIME REQUIREMENTS - # OF DAYS',
					key: 'lead_days',
					editLink: false,
				},
				{
					label: 'ORDER LEAD TIME REQUIREMENTS - DETAILS',
					key: 'lead_days_details',
					editLink: false,
				},
				{
					label: 'SHIPMENT FREQUENCY',
					key: 'shipment_frequency',
					editLink: false,
				},
				{
					label: 'FEES CHARGED BY SHIPPING INTERMEDIARY',
					key: 'fees_charged_by_si',
					editLink: false,
				},
				{
					label: 'CUSTOMS/TARIFFS CHARGED BY SHIPPING INTERMEDIARY',
					key: 'tariffs_applied_by_si',
					editLink: false,
				},
				{
					label: 'AVERAGE PAYMENT TERMS',
					key: 'average_payment_terms',
					editLink: false,
				},


			]
		}

		return keys[type];
	}

	const editSuccess = () => {
		updateData();
	}

	const editFailure = () => {

	}

	const listHeaders = (type:string):JSX.Element[] => {
		let headers:JSX.Element[] = getKeys(type).map((item, index) => {
			if(item.label.length ==0){
				return (<th key={index} className={styles.reportCheckbox}>{item.label}</th>);

			}
			else 
			{
				return (<th key={index}>{item.label}</th>);

			}
		});

		headers.push(<th key="_delete"></th>);

		return headers;
	}

	const deleteModel = (id:string) => {
		auth.deleteModel(id).then(() => {
			updateData();
		});
	}

	const updateData = () => {
		auth.getItems(props.type).then((response: any) => {
			setItems(response.data);
		});
	}
	const listProducts = (type:string):JSX.Element[] => {
		let productRows:JSX.Element[] = [];
		let productOptions:{label: string, value: string}[] = [];
		for (let product of parentList.products) {
			let index = productOptions.findIndex((item) => item.value === product.id);

			if(index === -1)
			{
				productOptions.push({value: product.id, label: product.name});

			}
		}
		for(let product of productOptions)
		{
			productRows.push(
				<option key={product.value} value={product.value}>{product.label}</option>
			);

		}


		return productRows;
	}
	const checkSelectedBoxes = (itemId:string) =>{
		
		
	}
	const listItems = (type:string):JSX.Element[] => {
		let rows:JSX.Element[] = [];
		for(let item of items) {
			rows.push(
				(
				<tr key={item.id}>
					{getKeys(type).map((key, index) => {
						if(key.key =="selected") {
							return (<td key={key.key} className={styles.reportCheckbox}><input type="checkbox" value={item.id} name={item.name} className="nodecheckbox" onClick={() => checkSelectedBoxes(item.id)}/></td>);
						}
						if (key.editLink) {
							return (<td className={styles.reportLink} key={key.key} onClick={() => editItem(item.id)}>{item[key.key]}</td>);
						}
						else {
							return (<td key={key.key}>{item[key.key]}</td>);
						}
						
					})}
					<td onClick={() => deleteModel(item.id)} className={styles.deleteBtn}>DELETE</td>
				</tr>
				)
			);
		}

		rowCount = rows.length;
		return rows;
	}

	const addNew = (e: any) => {
		editItem(props.type);

		e.stopPropagation();
	}
	const compareSupplyChain = (e: any) => {

		const allCheckboxes = document.getElementsByClassName("nodecheckbox") as HTMLCollectionOf<HTMLInputElement>;
		let selectedCheckboxes = [];
		let selectedHeadings = [];
		let tableHeader = document.querySelectorAll('thead tr') as NodeListOf<HTMLTableRowElement>;

		selectedHeadings.push(tableHeader);
		for(let i = 0; i < allCheckboxes.length; i++)
			{
				//console.log(allCheckboxes[i].checked);
				if(allCheckboxes[i].checked)
					{
						let nodeName = allCheckboxes[i].name;
						selectedCheckboxes.push(allCheckboxes[i].closest('tr'));
					}
			}

		selectedCheckboxes.length=2;
		
		appContext.setModalContent(<CompareModal onSuccess={editSuccess} onFailure={editFailure} compare={selectedCheckboxes} headings={selectedHeadings} />);

		appContext.showModal();
		e.stopPropagation();
	}

	const addSelected = (e: any) => {
		const allCheckboxes = document.getElementsByClassName("nodecheckbox") as HTMLCollectionOf<HTMLInputElement>;
		const selectedProductId = document.getElementById("selectedBulkProduct") as HTMLInputElement;
		let selectedCheckboxes = [];
		let itemsToSave:Array<any> = [];
		
		for(let i = 0; i < allCheckboxes.length; i++)
			{
				//console.log(allCheckboxes[i].checked);
				if(allCheckboxes[i].checked)
					{
						let nodeName = allCheckboxes[i].name;
						selectedCheckboxes.push({id:allCheckboxes[i].value,nodename:nodeName});
					}
			}

		
		//let foodName = (document.getElementById("food-name-val") as HTMLInputElement).value;
		console.log(selectedCheckboxes);

		for(let i = 0; i < selectedCheckboxes.length; i++)
		{
			let newNode = null;
			let newLinks:Array<LinkModel<LinkModelGenerics>> = [];
			let sourcePorts:Array<PortModel<PortModelGenerics>> = [];
			
			if (props.type === 'supplier') {
				newNode = new SupplierNodeModel({
					name: selectedCheckboxes[i].nodename, 
					parentID: selectedProductId.value,
					modelID: selectedCheckboxes[i].id,
	
				});
			}
			else if (props.type === 'shipping') {
				newNode = new ShippingNodeModel({
					name: selectedCheckboxes[i].nodename, 
					parentID: selectedProductId.value,
					modelID: selectedCheckboxes[i].id,
	
				});
			}
			else if (props.type === 'component') {
				newNode = new ComponentNodeModel({
					name: selectedCheckboxes[i].nodename, 
					parentID: selectedProductId.value,
					modelID: selectedCheckboxes[i].id,
	
				});
			}
			if (newNode === null ) {
				return;
			}

			let inPort = newNode.addInPort('In');
			newNode.addOutPort('Out');
	
			//newNode.setPosition(props.node.getX(), props.node.getY());
	
			for(let sourcePort of sourcePorts) {
	
				let newLink = sourcePort.createLinkModel();
				newLink?.setSourcePort(sourcePort);
				newLink?.setTargetPort(inPort);
	
				sourcePort.reportPosition();
				inPort.reportPosition();
	
				appContext.diagramEngine.getModel().addNode(newNode);
				if (newLink != null) {
					appContext.diagramEngine.getModel().addLink(newLink);
					newLinks.push(newLink);
				}
			}
	
	
			for (let link of newLinks) {
				itemsToSave.push(link.serialize());
			}
			
			itemsToSave.push(newNode.serialize());
		}

		

		auth.saveItems(itemsToSave).then(() => {
			console.log('saved..');
		});

		e.stopPropagation();
	}
	return (
		<div>
			<ReportSidebar></ReportSidebar>
			<div className={styles.reportContentWrapper}>
				<div className={styles.sectionContentWrapper}>
					
					<h1 className={styles.reportPageTitle}>{
						(() => {
							if (props.type === 'supplier') {
								return 'SUPPLIERS REPORT';
							}
							else if (props.type === 'shipping') {
								return 'SHIPPING INTERMEDIARY REPORT';
							}
							else if (props.type === 'component') {
								return 'COMPONENT REPORT';
							}
							else if (props.type === 'product') {
								return 'PRODUCTS REPORT';
							}
							else if (props.type === 'customer') {
								return 'CUSTOMERS REPORT';
							}
							else {
								return 'REPORT'
							}
						})()

					}</h1>
					<ul className={styles.reportActionMenu}>
						<li>
							<a className={styles.reportActionButtonAlt} onClick={compareSupplyChain}>COMPARE </a>
						</li>
						<li>
							<a className={styles.reportActionButton} href={'/report_csv/' + props.type}>DOWNLOAD </a>
						</li>                        <li>
							<a className={styles.reportActionButtonAlt} onClick={addNew}>ADD NEW </a>
						</li>
						
					</ul>
					<div className={styles.clearfix}></div>
				</div>
				<div className={styles.reportTableWrapper}>
{
	( () => {
		if(props.type !='product')
			{
				return (<><p>Bulk Actions for: <select id="selectedBulkProduct">
					{listProducts('product')}
					</select><a className={styles.reportBulkActionButton} onClick={addSelected}>ADD SELECTED TO SUPPLY CHAIN</a><br /></p><p></p></>);
			}
	})()
}
					


					<table>
						<thead>
							<tr>
								{ listHeaders(props.type) }
							</tr>
						</thead>
						<tbody>
							{ listItems(props.type) }
						</tbody>
					</table>
				</div>
				<div className={styles.reportFooterWrapper}>
					<strong>TOTAL: {rowCount}</strong>
				</div>
			</div>
		</div>
	);
};